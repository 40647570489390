import {useEffect, useState} from 'react';
import { Button } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';

export default function Index(){

    useEffect(()=>{
        window.scrollTo(0,0);
      },[]);
    return(
        <>
            <div>
                <Outlet />
            </div>
        </>
    )
}
import path from "path";
import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AppLayout from "../components/layouts/appLayout";
import ServiceLayout from "../pages/services/index";
import ProductsLayout from "../pages/productSpares/index";
import ContactLayout from "../pages/contactUs/index";
import AuthLayout from "../components/layouts/authLayout";
import AdminLayout from "../components/layouts/adminLayout";
import { paths } from "../config/constant";
const HomePage = lazy(() => import("../pages/home"));
const AboutUs = lazy(() => import("../pages/aboutus"));
const Associates = lazy(() => import("../pages/associates"));
//const Services       = lazy(() => import("../pages/services"));
const ServiceDesign = lazy(() => import('../pages/services/designEngg'));
const ServiceProcurement = lazy(() => import('../pages/services/procurement'));
const ServiceManagement = lazy(() => import('../pages/services/projectManagement'));
const ServiceConstruction = lazy(() => import('../pages/services/civilConstruction'));
const ServiceArchitecture = lazy(()=> import('../pages/services/architectureDrawing'))
const ServiceOM = lazy(() => import('../pages/services/omSpecialityServices'));
const ServiceTraining = lazy(() => import('../pages/services/trainingDevelopment'));
const ProductPlantEquipment = lazy(() => import("../pages/productSpares/balancePlantEquipment"));
const ProductOEMSpares = lazy(() => import("../pages/productSpares/oemSpares"));
const ProductIndustrialParts = lazy(() => import("../pages/productSpares/industrialHydraulicParts"));
const ContactForAssociates = lazy(() => import("../pages/contactUs/forAssociation"));
const ContactForJobSeekers = lazy(() => import("../pages/contactUs/forJobSeekers"));
const ContactForEnquires = lazy(() => import("../pages/contactUs/forEnquires"));

const ServiceHome = lazy(() => import('../pages/services/serviceshome'));
const ProductSparesHome = lazy(() => import('../pages/productSpares/productSpareshome'));


//admin
const Admin = lazy(() => import("../pages/admin/login"));
const Newsletter = lazy(() => import("../pages/admin/newsletter/index"));
const Dashboard = lazy(() => import("../pages/admin/dashboard/index"));
const Slider = lazy(() => import("../pages/admin/slider"));
const Contactus = lazy(() => import("../pages/admin/contactus"));


function RouteApp() {
  return (
    <>
      <Routes>
        <Route path={paths.home} element={<AppLayout />}>
          <Route
            path={paths.home}
            element={
              <Suspense fallback={""}>
                <HomePage />
              </Suspense>
            }
          />
          <Route
            path={paths.aboutus}
            element={
              <Suspense fallback={""}>
                <AboutUs />
              </Suspense>
            }
          />
          <Route
            path={paths.associates}
            element={
              <Suspense fallback={""}>
                <Associates />
              </Suspense>
            }
          />

        <Route
            path={paths.servicesHome}
            element={
              <Suspense fallback={""}>
                <ServiceHome />
              </Suspense>
            }
          />

          {/* <Route path={paths.services} element={<Suspense fallback={""}><Services /></Suspense>} /> */}
          <Route path={paths.services} element={<ServiceLayout />}>
            <Route
              path={paths.servicesDesignEngg}
              element={
                <Suspense fallback={""}>
                  <ServiceDesign />
                </Suspense>
              }
            />
            <Route
              path={paths.servicesProcurement}
              element={
                <Suspense fallback={""}>
                  <ServiceProcurement />
                </Suspense>
              }
            />
            <Route
              path={paths.servicesProjectManagement}
              element={
                <Suspense fallback={""}>
                  <ServiceManagement />
                </Suspense>
              }
            />
             <Route
              path={paths.servicesArchitecture}
              element={
                <Suspense fallback={""}>
                  <ServiceArchitecture />
                </Suspense>
              }
            />
            <Route
              path={paths.servicesCivilConstruction}
              element={
                <Suspense fallback={""}>
                  <ServiceConstruction />
                </Suspense>
              }
            />
            <Route
              path={paths.servicesOMServices}
              element={
                <Suspense fallback={""}>
                  <ServiceOM />
                </Suspense>
              }
            />
            <Route
              path={paths.servicesTraining}
              element={
                <Suspense fallback={""}>
                  <ServiceTraining />
                </Suspense>
              }
            />
          </Route>

          <Route
            path={paths.productandsparesHome}
            element={
              <Suspense fallback={""}>
                <ProductSparesHome />
              </Suspense>
            }
          />

          <Route path={paths.products} element={<ProductsLayout />}>
            <Route path={paths.productsBalancePlantEquipment} element={<Suspense fallback={""}><ProductPlantEquipment /></Suspense>} />
            <Route path={paths.productsOEMSpares} element={<Suspense fallback={""}><ProductOEMSpares /></Suspense>} />
            <Route path={paths.productsIndustrialParts} element={<Suspense fallback={""}><ProductIndustrialParts /></Suspense>} />
          </Route>
          <Route path={paths.contacts} element={<ContactLayout />}>
            <Route path={paths.contactForAssociation} element={<Suspense fallback={""}><ContactForAssociates /></Suspense>} />
            <Route path={paths.contactForJobSeekers} element={<Suspense fallback={""}><ContactForJobSeekers /></Suspense>} />
            <Route path={paths.contactForEnquires} element={<Suspense fallback={""}><ContactForEnquires /></Suspense>} />
          </Route>
        </Route>

         {/* Admin  */}
        <Route path={paths.adminLogin} element={<AuthLayout />}>
          <Route path={paths.adminLogin} element={<Suspense fallback={""}><Admin /></Suspense>} />
        </Route>

        <Route path={""} element={<AdminLayout />}>
          <Route path={paths.ADMIN.dashboard}  element={<Suspense fallback={""}> <Dashboard />  </Suspense>} />
          <Route path={paths.ADMIN.newsletter} element={<Suspense fallback={""}> <Newsletter /> </Suspense>} />
          <Route path={paths.ADMIN.slider}     element={<Suspense fallback={""}> <Slider />     </Suspense>} />
          <Route path={paths.ADMIN.contactus}  element={<Suspense fallback={""}> <Contactus />  </Suspense>} />
        </Route>

      </Routes>
    </>
  );
}

export default RouteApp;

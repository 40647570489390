import { Outlet } from "react-router-dom";

export default function Index(){
    return(
        <>
        <div className="container-fluid">
            <Outlet />
        </div>
        </>
    )
}
export const insertUpdateLocalStorage = (storeKey: string, data: any) => {
    debugger;
    const _temp: any = JSON.parse(localStorage.getItem(storeKey) || 'null');
    localStorage.setItem(storeKey, JSON.stringify({ ..._temp, ...data }));
}
export const getLocalStorage = (storeKey: string) => {
    return JSON.parse(localStorage.getItem(storeKey) || 'null');
}

export const removeLocalStorage = (storeKey:string) => {
    localStorage.removeItem(storeKey);
}
import footLogo from "../../assets/images/Agile_Logo_Footer.webp";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import fb from "../../assets/images/Facebook.webp";
import insta from "../../assets/images/Instagram.webp";
import linkedIn from "../../assets/images/Linkedin.webp";
import tweet from "../../assets/images/Twitter.webp";
import sysmedac from "../../assets/images/sysmedac.webp";
import footImg from "../../assets/images/footer_illustration.webp";
import isoLogo from "../../assets/images/iso-logo.webp";
import { useNavigate } from "react-router-dom";
import { paths } from "../../config/constant";

function Footer() {
  const Navigate = useNavigate();
  
  return (
    <>
      <div style={{ border: "2px solid #DB6F09" }}>
        <div className="footerArea">
          <div className="col-md-12">
            {/* <div className="footLogo d-flex justify-content-center align-items-center p-3">
              <img style={{ height: "4.5em" }} src={footLogo} alt="logo" />
            </div>
            <div className="col-md-12 d-flex flex-wrap p-3 footArea2">
              <div className="col-md-4 col-sm-12 bR p-3" style={{ lineHeight: "2em" }}>
                <div className="row">
                  <div className="col-md-1 col-sm-1 mob-Hide">
                    <ApartmentIcon className="apartmentIco" />
                  </div>
                  <div className="col-md-11 col-sm-12">
                    <label className="fs-18 pb-2 d-flex">
                      <ApartmentIcon className="desk-Hide apartmentIco" />Registered Office
                    </label>
                    <p className="fs-14 lh-24 mb-0">
                      21/11, Labour Colony Road, 1st Street, SIDCO Industrial Estate,
                      <br />
                      Guindy, Chennai 600032, Tamil Nadu, India
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-sm-12 d-flex flex-column bR p-3" style={{ lineHeight: "2em" }}>
                <div className="row">
                  <div className="col-md-1 col-sm-1 mob-Hide">
                    <ApartmentIcon className="apartmentIco" />
                  </div>
                  <div className="col-md-11 col-sm-11">
                    <label className="fs-18 pb-2 d-flex">
                      <ApartmentIcon className="desk-Hide apartmentIco" />Corporate Office
                    </label>
                    <p className="fs-14 lh-24 mb-0">
                      NSIC - Software Technology Business Park Module-105
                      <br />
                      B-24, Guindy Industrial Estate, Ekkaduthangal
                      <br />
                      Chennai 600032, Tamil Nadu, India
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-12 p-3 getInTouch">
                <div className="row">
                  <label className="fs-18 pb-2">Get in Touch</label>
                  <p className="my-0">
                    <a
                      className="text-decoration-none fs-14"
                      href="tel:+919176353378"
                    >
                      <CallIcon className="fs-18 apartmentIco" /> +91 91 76 35 33 78
                    </a>
                  </p>
                  <p className="my-0">
                    <a
                      className="text-decoration-none fs-14"
                      href="mailto:info@agilepmc.com"
                    >
                      <EmailIcon className="fs-18 apartmentIco" /> info@agilepmc.com
                    </a>
                  </p>
                </div>
                <div className="col-md-10 justify-content-between align-items-center socialLink pt-1">
                  <a href="https://www.facebook.com">
                    <img className="socialImg" src={fb} alt="fb" />
                  </a>
                  <a href="https://www.twitter.com">
                    <img className="socialImg" src={tweet} alt="tweet" />
                  </a>
                  <a href="https://www.instagram.com">
                    <img className="socialImg" src={insta} alt="insta" />
                  </a>
                  <a href="https://www.linkedin.com/">
                    <img className="socialImg" src={linkedIn} alt="linkedIn" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-12 d-flex justify-content-center py-2">
              <div className="col-md-8 quickLinkDiv d-flex flex-column align-items-center bg-light-1">
                <label className="fs-18 pb-3">Quick Links</label>
                <div className="col-md-10 fs-14 d-flex flex-wrap quickLinks">
                  <a className="pointer text-decoration-none py-2 px-4" onClick={() => Navigate(paths.home)}>Home</a>
                  
                  <a className="pointer text-decoration-none py-2 px-4" onClick={() => Navigate(paths.aboutus)}>About Us</a>
                  
                  <a className="pointer text-decoration-none py-2 px-4" onClick={() => Navigate(paths.servicesDesignEngg)}>Services</a>
                  
                  <a className="pointer text-decoration-none py-2 px-4" onClick={() => Navigate(paths.productsBalancePlantEquipment)}>Products & Spares</a>
                                    
                  <a className="pointer text-decoration-none py-2 px-4" onClick={() => Navigate(paths.contactForAssociation)}>Contact Us</a>
                  
                  <a className="pointer text-decoration-none py-2 px-4" onClick={() => Navigate(paths.associates)}>Associates</a>
                  
                </div>
              </div>
            </div> */}

            <div className="col-md-12 d-flex justify-content-center" >
              <img src={footImg} className="w-100" />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center flex-wrap p-4 poweredBy">
          <div className="d-flex align-items-center">
            <img src={isoLogo} style={{ width: "3em", padding: "0px" }} alt="ISO-Certificate"  />
            <div className="fs-6 fw-bold ms-2">ISO 9001:2015 Certification</div>
          </div>
          <div className="foot-2 fs-6 fw-bold">
            AGILE PMC <strong className="fs-18 px-1"> &#169; </strong> 2022 All Rights Reserved | Powered by{" "}
            <img
              style={{ width: "6em", padding: "0px" }}
              src={sysmedac}
              alt="sysmedac"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;

//import { Button, Paper } from '@mui/material';
import { Button } from 'react-bootstrap';
import { Outlet, useNavigate } from 'react-router-dom';
import { paths } from '../../config/constant';


export default function Index(){
    const navigate = useNavigate();
    return(
        <>
            <div>
                <Outlet />
                <div className='container'>
                    <div className='d-flex justify-content-center align-items-center my-3'>
                       
                        <Button className="servButton" onClick={() => navigate(paths.contactForEnquires)}>GET IN TOUCH</Button>
                       
                    </div>
                </div>
            </div>
        </>
    )
}
import React from 'react'
import { Outlet } from 'react-router-dom';
import "../../assets/scss/app.scss";
import AdminHeader from './adminHeader';
import AdminFooter from './adminFooter';

function AdminLayout() {

  return (<div>
    <AdminHeader />
    <div style={{ paddingTop: "5em" }}>
      <Outlet />
    </div>
    <AdminFooter />

  </div>
  )
}

export default AdminLayout